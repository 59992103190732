// project specific js

requirejs([ 'jquery' ], function($) {

  // Top-Navigation manupulieren
  var $buttonLink = "<a href='https://hausbankrechner.vb-hochrhein.de' class='top-nav__btn top-nav__btn--login top-nav__btn--hausbankmodell' target='_blank'>Hausbankmodell</a>";
  var $buttonIcon = '<svg class="top-nav__icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-link-extern" href="#icon-link-extern"></use></svg>';
  $(".top-nav__btn.top-nav__btn--contact ").after($buttonLink);
  $(".top-nav__btn--hausbankmodell").append($buttonIcon);
  
})

// cookieconsent abfragen
// function getCookie(key) {
//     var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)'); 
//     return keyValue ? keyValue[2] : null; 
// } 
  
// function isConsent(category) { 
//   var consent = getCookie("cookieConsent") 
  
//   if (consent) { 
//     try { categories = JSON.parse(decodeURIComponent(consent)); 
//       if (Array.isArray(categories)) { 
//         for (var i = 0, entry; entry = categories[i]; i++) { 

//           if (entry.name === category && entry.value === "on") {  return true;  } 
//           if (entry.name === category && entry.value === "off") {  return false; } 
//         } 
//       } 
//     } catch (e) { } 
//   } return false; 
// }
// end

//statistik cookie wurde zugestimmt
// if (isConsent("statistik") == true) { 
//     if(window.location.hostname == 'www.volksbank-hochrhein.de') {
//         var gaProperty = 'UA-39508708-1';
//         var disableStr = 'ga-disable-' + gaProperty;
//         if (document.cookie.indexOf(disableStr + '=true') > -1) {
//             window[disableStr] = true;
//         }
//         function gaOptout() {
//             document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
//             window[disableStr] = true;
//         }
//         (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
//         (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
//         m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
//         })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
//         ga('create', 'UA-39508708-1', 'volksbank-hochrhein.de');  // GA Code und Webseite eintragen
//         ga('set', 'anonymizeIp', true);
//         ga('send', 'pageview');
//     }
// }
// // end - cookie consent GA hinzugefügt 